<template>
  <UiPopup :model-value="modelValue" size="mediumBig" full-height without-padding>
    <template #custom>
      <div class="flex h-full">
        <div
          class="flex h-full w-1/4 min-w-[192px] flex-col items-center border-r-[1.5px] border-black-40 bg-black-05 p-8"
        >
          <div class="flex max-h-[calc(100%_-_72px)] max-w-[128px] flex-1 flex-col gap-6 overflow-auto">
            <div
              v-for="(file, index) in token.files"
              :key="file.id"
              class="cursor-pointer rounded-lg bg-white p-3"
              :class="{ 'border-[1.5px] border-primary-100': visibleFileIndex === index }"
              @click="visibleFileIndex = index"
            >
              <div class="mb-1.5 h-[146px] w-full rounded bg-primary-05">
                <img
                  v-if="file?.mime_type.split('/').includes('image') && linksToFilesPreview[file.id]"
                  :src="linksToFilesPreview[file.id]"
                  :alt="file.name"
                  class="h-full"
                />
                <PDF
                  v-else-if="file?.mime_type.split('/').includes('pdf') && linksToFilesPreview[file.id]"
                  :key="file.id"
                  class="w-full overflow-hidden"
                  :src="linksToFilesPreview[file.id]"
                />
                <div v-else class="flex size-full items-center justify-center">
                  <UiCircleLoader />
                </div>
              </div>
              <div class="flex items-start justify-between">
                <span class="text-caption-3 w-[calc(100%_-_16px)] pt-0.5 text-start text-black-60">
                  {{ fileName(file) }}
                </span>
                <UiIcon
                  name="download"
                  class="cursor-pointer text-black-60"
                  size="xxs"
                  @click.stop="downloadFileClick(file)"
                />
              </div>
            </div>
          </div>
          <div class="mt-8 flex w-full justify-center">
            <UiButtonBase :id="`token-${token.id}`" class="w-full" @click="verifyToken">
              {{ $t('verify') }}
            </UiButtonBase>
          </div>
        </div>
        <div class="flex min-w-[calc(100%_-_192px)] flex-col bg-black-20">
          <div class="flex items-start border-b-[1.5px] border-black-40 bg-black-05 px-8 pb-6 pt-8">
            <div class="flex-1 flex-col items-start justify-start text-start">
              <h4 class="mb-2">{{ $t('token_payment_validation') }}</h4>
              <div class="text-body-2 text-black-70">{{ $t('token_payment_validation_subtitle1') }}</div>
              <div class="text-body-2 text-black-70">{{ $t('token_payment_validation_subtitle2') }}</div>
            </div>
            <div class="flex gap-4">
              <UiIcon
                name="download"
                class="cursor-pointer text-black-70"
                size="sm"
                @click.stop="token.files && downloadFileClick(token.files[visibleFileIndex])"
              />
              <UiIcon name="big-close" class="cursor-pointer" @click="emits('update:modelValue', false)" />
            </div>
          </div>
          <div class="flex h-[calc(100%_-_125px)] w-full items-center justify-center bg-black-20 pt-4">
            <img
              v-if="
                token.files &&
                linksToFilesPreview[token.files[visibleFileIndex].id] &&
                token.files[visibleFileIndex]?.mime_type.split('/').includes('image')
              "
              :src="linksToFilesPreview[token.files[visibleFileIndex].id]"
              :alt="token.files[visibleFileIndex].name"
              class="m-auto max-h-full max-w-full"
            />
            <PDF
              v-else-if="
                token.files &&
                linksToFilesPreview[token.files[visibleFileIndex].id] &&
                token.files[visibleFileIndex]?.mime_type.split('/').includes('pdf')
              "
              :key="token.files[visibleFileIndex].id"
              :show-page-tooltip="false"
              :show-back-to-top-btn="false"
              class="mx-auto w-[85%]"
              :src="linksToFilesPreview[token.files[visibleFileIndex].id]"
            />

            <UiCircleLoader v-else />
          </div>
        </div>
      </div>
    </template>
  </UiPopup>
</template>

<script setup lang="ts">
import PDF from 'pdf-vue3'
import { useUiStore } from '~/store/ui'
import type { UnitPaymentToken, UnitPaymentTokenFile } from '~/types/properties'
import { PAYMENT_TOKEN_FILE_ENTITY_TYPES } from '~/constants'

const emits = defineEmits(['update:modelValue', 'refetch'])

type Props = {
  modelValue: boolean
  token: UnitPaymentToken
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const uiStore = useUiStore()

const visibleFileIndex = ref<number>(0)
const linksToFilesPreview = ref<any>({})

const verifyToken = async () => {
  try {
    await useVerifyPaymentLink(props.token.id)

    uiStore.showSnackBanner('Payment token verified successfully', 'success')
    emits('update:modelValue', false)
    emits('refetch')
  } catch {
    uiStore.showSnackBanner('Failed to verify payment, please try again later', 'error')
  }
}

const fileName = (file: UnitPaymentTokenFile) => {
  if (file.entity === PAYMENT_TOKEN_FILE_ENTITY_TYPES.PASSPORT) {
    return 'Lead passport'
  } else if (file.entity === PAYMENT_TOKEN_FILE_ENTITY_TYPES.PAYMENT_CONFIRMATION) {
    return 'Payment confirmation'
  }
  return file.name
}

const downloadFile = async (file: UnitPaymentTokenFile) => {
  const response = await useDownloadTokenFile(file.id)

  const reader = new FileReader()
  // @ts-ignore
  reader.readAsDataURL(response)
  reader.onloadend = () => {
    linksToFilesPreview.value[file.id] = reader.result
  }
}

const downloadFilesForPreview = () => {
  props.token.files?.forEach((file) => {
    downloadFile(file)
  })
}

const downloadFileClick = async (file: UnitPaymentTokenFile) => {
  await useDownloadFile(linksToFilesPreview.value[file.id], file.name)
}

onMounted(() => {
  downloadFilesForPreview()
})
</script>

<style scoped></style>
